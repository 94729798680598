<template>
  <div class="view-home">
    <div class="one-seminar-title-block">
      <div class="container" style="position: relative; z-index: 10">
        <nav class="breadcrumbs-block">
          <ul class="breadcrumbs-list">
            <li class="breadcrumbs-item"><router-link to="/">{{ $t('navbar.home') }}</router-link></li>
            <li class="breadcrumbs-item"><router-link to="/events">{{ $t('events-main') }}</router-link></li>
            <li class="breadcrumbs-item">{{ $t('seminarsTrainings') }}</li>
          </ul>
        </nav>
        <div class="one-forum-title">
          {{ $t('seminarsTrainings') }}
        </div>
        <div class="one-forum-subtitle">
          <router-link to="/events">
            <svg width="65" height="66" viewBox="0 0 65 66" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M32.5 57.375C45.9619 57.375 56.875 46.4619 56.875 33C56.875 19.5381 45.9619 8.625 32.5 8.625C19.0381 8.625 8.125 19.5381 8.125 33C8.125 46.4619 19.0381 57.375 32.5 57.375Z"
                  stroke="white" stroke-width="3" stroke-miterlimit="10"/>
              <path d="M36.5625 23.8594L26.4062 33L36.5625 42.1406" stroke="white" stroke-width="3"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </router-link>
          {{ seminarInfo[`name_${$i18n.locale}`] || seminarInfo.name_ru }}
          {{ seminarInfo[`title_${$i18n.locale}`] || seminarInfo.title_ru }}
        </div>
      </div>
    </div>

    <div class="container one-forum-details-desktop">
      <div class="one-forum-details-img" :style="{ 'background-image': `url(${getImagePath(seminarInfo.img)})` }">
        <!--        <el-image-->
        <!--            style="width: 100%; height: 100%"-->
        <!--            src="/images/forums-slide-pdf-trainings-back-1.png"-->
        <!--            fit="fit"></el-image>-->
        <div class="one-forum-tags">
          <div class="one-forum-tag">
            {{ $t('numberHours') }}:12 часов
          </div>
          <div class="one-forum-tag">
            {{ $t('dateOfEvent') }}: {{seminarInfo.start_date}}
          </div>
          <div class="one-forum-tag">
            {{ $t('venue') }}: {{ seminarInfo[`location_${$i18n.locale}`] || seminarInfo.location_ru }}
          </div>
        </div>
        <div>
          <div class="one-forum-img-title">
            {{ seminarInfo[`name_${$i18n.locale}`] || seminarInfo[`title_${$i18n.locale}`] }}
          </div>
          <a class="one-forum-img-link" @click="openModal" v-if="isBeforeForumDate">
            {{ $t('signup.signup-btn') }}
          </a>

        </div>

      </div>
    </div>

    <div class="one-forum-details-img one-forum-details-mobile" :style="{ 'background-image': `url(${getImagePath(seminarInfo.img)})` }">
      <!--        <el-image-->
      <!--            style="width: 100%; height: 100%"-->
      <!--            src="/images/forums-slide-pdf-trainings-back-1.png"-->
      <!--            fit="fit"></el-image>-->
      <div class="one-forum-tags">
        <div class="one-forum-tag">
          {{ $t('numberHours') }}:12 часов
        </div>
        <div class="one-forum-tag">
          {{ $t('dateOfEvent') }}: {{seminarInfo.start_date}}
        </div>
        <div class="one-forum-tag">
          {{ $t('venue') }}: {{ seminarInfo[`location_${$i18n.locale}`] || seminarInfo.location_ru }}
        </div>
      </div>
      <div>
        <div class="one-forum-img-title">
          {{ seminarInfo[`goal_${$i18n.locale}`] || seminarInfo.goal_ru }}
        </div>
        <a class="one-forum-img-link" @click="openModal" v-if="isBeforeForumDate">
          {{ $t('signup.signup-btn') }}
        </a>

      </div>

    </div>

    <div class="one-seminar-back ">
    <div class="container">
      <div class="one-forum-details-block">
        <div class="one-forum-details-left">
          <div class="line-top"></div>
          <div class="one-seminar-section-title">
            {{ $t('seminarTitlePage1') }}
          </div>
          <div class="one-forum-section-text">
            {{ seminarInfo[`goal_${$i18n.locale}`] || seminarInfo.goal_ru }}
            {{ seminarInfo[`purpose_${$i18n.locale}`] || seminarInfo.purpose_ru }}
          </div>
          <div class="line-top"></div>
          <div class="one-seminar-section-title">
            {{ $t('seminarTitlePage2') }}
          </div>
          <div class="one-forum-section-text">
            {{ seminarInfo[`tasks_${$i18n.locale}`] || seminarInfo.tasks_ru }}
          </div>
        </div>
        <div class="one-forum-details-right">
          <div class="one-forum-results">
            <div class="one-forum-results-title">
              {{ $t('resultsForum') }}
            </div>
            <div class="one-forum-results-text">
              {{ seminarInfo[`expected_results_${$i18n.locale}`] || seminarInfo.expected_results_ru }}
            </div>
            <div class="one-forum-results-details">
              <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="61" height="61" rx="13.9377" fill="#F1E8FF"/>
                <path
                    d="M36.7814 16.3684V22.6491M24.2201 16.3684V22.6491M17.9395 28.9297H43.062M17.9395 22.6491C17.9395 21.8162 18.2703 21.0174 18.8592 20.4285C19.4482 19.8396 20.2469 19.5087 21.0798 19.5087H39.9217C40.7546 19.5087 41.5533 19.8396 42.1423 20.4285C42.7312 21.0174 43.062 21.8162 43.062 22.6491V41.491C43.062 42.3239 42.7312 43.1226 42.1423 43.7115C41.5533 44.3005 40.7546 44.6313 39.9217 44.6313H21.0798C20.2469 44.6313 19.4482 44.3005 18.8592 43.7115C18.2703 43.1226 17.9395 42.3239 17.9395 41.491V22.6491ZM24.2201 35.2104H27.3604V38.3507H24.2201V35.2104Z"
                    stroke="#6E00FA" stroke-width="2.28893" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              {{ $t('datesOfEvent') }}: {{formattedDate(seminarInfo.start_date, seminarInfo.end_date)}}
            </div>
            <div class="one-forum-results-details">
              <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="61" height="61" rx="13.833" fill="#F1E8FF"/>
                <path
                    d="M25.5785 28.8593C25.5785 30.1647 26.0971 31.4167 27.0202 32.3397C27.9432 33.2628 29.1952 33.7814 30.5006 33.7814C31.806 33.7814 33.058 33.2628 33.9811 32.3397C34.9041 31.4167 35.4227 30.1647 35.4227 28.8593C35.4227 27.5539 34.9041 26.3019 33.9811 25.3788C33.058 24.4558 31.806 23.9372 30.5006 23.9372C29.1952 23.9372 27.9432 24.4558 27.0202 25.3788C26.0971 26.3019 25.5785 27.5539 25.5785 28.8593Z"
                    stroke="#6E00FA" stroke-width="2.27174" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M39.7821 38.1407L32.8206 45.1022C32.2053 45.7169 31.3711 46.0621 30.5014 46.0621C29.6317 46.0621 28.7976 45.7169 28.1823 45.1022L21.2192 38.1407C19.3836 36.305 18.1336 33.9663 17.6272 31.4202C17.1208 28.8741 17.3807 26.235 18.3742 23.8367C19.3677 21.4383 21.05 19.3884 23.2085 17.9462C25.367 16.5039 27.9046 15.7342 30.5006 15.7342C33.0966 15.7342 35.6343 16.5039 37.7927 17.9462C39.9512 19.3884 41.6336 21.4383 42.627 23.8367C43.6205 26.235 43.8805 28.8741 43.3741 31.4202C42.8677 33.9663 41.6176 36.305 39.7821 38.1407Z"
                    stroke="#6E00FA" stroke-width="2.27174" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              {{ $t('venue') }}: {{ seminarInfo[`location_${$i18n.locale}`] || seminarInfo.location_ru }}
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

    <div class="container">
      <div class="one-forum-speakers-block">

        <div class="line-top"></div>
        <div class="one-seminar-section-title">
          {{ $t('speakersForum') }}
        </div>
        <div class="one-training-speakers-slider">
          <carousel :perPage="3" :loop="true" :autoplay="false" :scroll-per-page="false" :autoplayTimeout="5000"
                    :navigationEnabled="true"
                    :paginationEnabled="false">
            <slide class="one-forum-slide-block" v-for="slide in seminarInfo.speakers">
              <div class="one-forum-slide">
                <div class="one-forum-slide-details">
                  <img :src="getImagePath(slide.avatar)" width="80" height="80" alt=""/>
                  <div>
                    <div class="one-forum-slide-name">
                      {{slide[`fio_${$i18n.locale}`] || slide.fio_ru}}
                    </div>
                    <div class="one-forum-slide-job">
                      {{slide[`position_${$i18n.locale}`] || slide.position_ru}}
                    </div>
                  </div>
                </div>
                <div class="one-forum-slide-text">
                  {{slide[`description_${$i18n.locale}`] || slide.description_ru}}
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </div>

    <div class="one-seminar-program-back">
    <div class="container">
      <div class="one-seminar-program-block">
        <div class="line-top"></div>
        <div class="one-seminar-section-title">
          {{ $t('seminarTitlePage3') }}
        </div>

        <a class="one-forum-program-link" download :href="seminarInfo.file" v-if="seminarInfo.file">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M11.0234 17.6187C10.8148 17.6187 10.6062 17.5391 10.4492 17.382L6.79766 13.7281C6.48359 13.4117 6.48359 12.8984 6.8 12.582C7.11406 12.2656 7.62734 12.2656 7.94375 12.582L10.2148 14.8531V1.31094C10.2148 0.863281 10.5781 0.5 11.0258 0.5C11.4734 0.5 11.8367 0.863281 11.8367 1.31094V14.8531L14.1078 12.582C14.2602 12.4297 14.4641 12.3453 14.6797 12.3453C14.8953 12.3453 15.0992 12.4297 15.2516 12.582C15.568 12.8984 15.568 13.4117 15.2516 13.7281L11.5977 17.382C11.4383 17.5391 11.232 17.6187 11.0234 17.6187ZM19.8781 14.8203C19.8781 14.3727 20.2414 14.0094 20.6891 14.0094C21.1367 14.0094 21.5 14.3727 21.5 14.818V20.0422C21.5 20.8438 20.8484 21.4977 20.0445 21.4977H1.95547C1.15391 21.4977 0.5 20.8461 0.5 20.0422V14.818C0.5 14.3727 0.860938 14.007 1.30859 14.007C1.75625 14.007 2.11953 14.3703 2.11953 14.818V19.4C2.11953 19.6672 2.3375 19.8828 2.60234 19.8828H19.3953C19.6625 19.8828 19.8781 19.6648 19.8781 19.4V14.8203Z"
                  fill="#1090CB"/>
          </svg>
          Программа Семинара.pdf*
        </a>
        <div class="one-forum-program-days" ref="oneSeminarProgramCarousel">
          <div class="one-forum-speakers-slider">
            <carousel :perPage="perPageDays" :loop="false" :autoplay="false" :scroll-per-page="false" :autoplayTimeout="5000"
                      :navigationEnabled="true" :center="true"
                      :paginationEnabled="false" class="one-forum-speakers-carousel" ref="carousel">
              <slide class="one-forum-slide-block" v-for="(item, index) in daysProgram" :key="index">
                <div class="one-forum-slide-wrapper">
                  <div class="one-forum-slide">
                    <div class="one-forum-slide-title">
                      День {{ item[`day_${$i18n.locale}`] }}
                    </div>
                    <div class="one-forum-slide-subtitle">
                      {{ item.title }}
                    </div>

                    <div class="one-forum-slide-day-section">
                      <div class="one-forum-slide-day-wrapper" v-for="(slide, number) in item.data">

<!--                        <div class="one-forum-slide-desc">-->
<!--                          <div class="one-forum-slide-desc-title">-->
<!--                            {{ $t('programForum2') }}-->
<!--                          </div>-->
<!--                          <div class="one-forum-slide-desc-text">-->
<!--                            {{ slide[`text_${$i18n.locale}`] }}-->
<!--                          </div>-->
<!--                        </div>-->
<!--                        <div class="one-forum-slide-desc">-->
<!--                          <div class="one-forum-slide-desc-title">-->
<!--                            {{ $t('programForum3') }}-->
<!--                          </div>-->
<!--                          <div class="one-forum-slide-desc-text">-->
<!--                            {{ slide[`hall_${$i18n.locale}`] }}-->
<!--                          </div>-->
<!--                          <div class="one-forum-slide-desc-text">-->
<!--                            &lt;!&ndash;                        Спикер: {{slide.moderator[`fio_${$i18n.locale}`] || slide.moderator.fio_ru}}&ndash;&gt;-->
<!--                          </div>-->
<!--                        </div>-->
                        <div class="one-forum-slide-desc">
<!--                          <div class="one-forum-slide-desc-title">-->
<!--                            {{ $t('programForum5') }}-->
<!--                          </div>-->
                          <div class="one-forum-slide-desc-text" style="color: #1090CB;">
                            {{ formatTime(slide.time) }}
                          </div>
                          <div class="one-forum-slide-desc-text" v-html="slide[`text_${$i18n.locale}`]"></div>
<!--                          <div class="one-forum-slide-desc-text">-->
<!--                            {{ formattedDate(slide.start_date, slide.end_date) }}-->
<!--                          </div>-->
                          <!--                      <div class="one-forum-slide-desc-text">-->
                          <!--                        05.07.2024 - 07.07.2024 гг-->
                          <!--                      </div>-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </slide>
            </carousel>
          </div>
        </div>

        <div class="one-seminar-program-link" @click="openModal" v-if="isBeforeForumDate">
          <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.0273 22.1328C9.69141 26.1055 4.39453 26.1055 4.39453 26.1055C4.39453 26.1055 4.39453 20.8086 8.36719 19.4727"
                stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M22.957 12.8517L15 20.8087L9.69141 15.5001L17.6484 7.5431C20.6602 4.53139 23.6719 4.56654 24.9609 4.75404C25.1598 4.78058 25.3445 4.87184 25.4864 5.01374C25.6283 5.15564 25.7196 5.34029 25.7461 5.5392C25.9336 6.82826 25.9687 9.83998 22.957 12.8517Z"
                stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M21.6328 14.1758V21.7461C21.6293 21.9934 21.5282 22.2293 21.3516 22.4023L17.5664 26.1992C17.4463 26.3192 17.2959 26.4043 17.1313 26.4454C16.9666 26.4866 16.7938 26.4823 16.6314 26.433C16.469 26.3836 16.3231 26.2912 16.2091 26.1654C16.0951 26.0396 16.0175 25.8852 15.9844 25.7188L15 20.8086"
                stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M16.323 8.86719H8.75272C8.50544 8.87071 8.26956 8.9718 8.09647 9.14844L4.2996 12.9336C4.17965 13.0537 4.09455 13.2041 4.05338 13.3687C4.01222 13.5334 4.01652 13.7062 4.06585 13.8686C4.11517 14.031 4.20766 14.1769 4.33344 14.2909C4.45923 14.4049 4.61359 14.4825 4.78007 14.5156L9.69022 15.5"
                stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          {{ $t('registerForSeminar') }}
        </div>
      </div>
    </div>
    </div>
    <el-dialog
        :visible.sync="modalSuccessfullyRegistered"
        :width="modalSuccessfullyRegisteredWidth">

      <div class="modal-success-block">
        <img :src="backUrl + seminarInfo.img" width="100%" alt="">
        <div class="modal-success-title">
          {{ $t('successfullyRegistered') }}
        </div>
        <div class="modal-success-text">
          {{ seminarInfo[`name_${$i18n.locale}`] || seminarInfo.name_ru }}
        </div>
        <div class="modal-success-questions">
          {{ $t('successfullyRegistered1') }}
        </div>
        <div class="d-flex justify-content-between">
          <a href="mailto:qabilet@el-umiti.kz" class="modal-success-email">
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.7725 16.2839H6.08694C3.78126 16.2839 2.24414 15.131 2.24414 12.4411V7.06118C2.24414 4.37122 3.78126 3.21838 6.08694 3.21838H13.7725C16.0782 3.21838 17.6153 4.37122 17.6153 7.06118V12.4411C17.6153 15.131 16.0782 16.2839 13.7725 16.2839Z" stroke="#1090CB" stroke-width="1.53712" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.7735 7.44543L11.3679 9.36683C10.5763 9.99705 9.27741 9.99705 8.48579 9.36683L6.08789 7.44543" stroke="#1090CB" stroke-width="1.53712" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            qabilet@el-umiti.kz
          </a>
          <div class="modal-success-phone">
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.84721 17.821C14.3041 17.821 17.9171 14.208 17.9171 9.75114C17.9171 5.29428 14.3041 1.68127 9.84721 1.68127C5.39035 1.68127 1.77734 5.29428 1.77734 9.75114C1.77734 11.1985 2.15836 12.5568 2.82555 13.7313L1.77734 17.821L5.99377 16.8433C7.13884 17.4668 8.45165 17.821 9.84721 17.821ZM9.84721 16.5795C13.6184 16.5795 16.6756 13.5223 16.6756 9.75114C16.6756 5.97995 13.6184 2.92279 9.84721 2.92279C6.07602 2.92279 3.01886 5.97995 3.01886 9.75114C3.01886 11.2072 3.47461 12.5568 4.25124 13.6652L3.63962 15.9587L5.97364 15.3752C7.07427 16.1347 8.4088 16.5795 9.84721 16.5795Z" fill="#1090CB" stroke="#1090CB" stroke-width="0.153712"/>
              <path d="M7.83107 6.00438C7.6392 5.619 7.34487 5.65312 7.04752 5.65312C6.51611 5.65312 5.6875 6.28964 5.6875 7.47429C5.6875 8.44516 6.11532 9.50794 7.55692 11.0978C8.94818 12.632 10.7762 13.4257 12.2938 13.3987C13.8114 13.3717 14.1236 12.0657 14.1237 11.6247C14.1237 11.4292 14.0023 11.3317 13.9188 11.3052C13.4016 11.057 12.4478 10.5946 12.2308 10.5077C12.0137 10.4208 11.9004 10.5383 11.83 10.6022C11.6332 10.7898 11.243 11.3425 11.1094 11.4669C10.9759 11.5912 10.7767 11.5283 10.6938 11.4813C10.3889 11.3589 9.562 10.9911 8.90289 10.3522C8.08775 9.56198 8.03991 9.29011 7.88634 9.04812C7.76348 8.85452 7.85363 8.73574 7.89862 8.68383C8.07425 8.48118 8.31675 8.16832 8.4255 8.01284C8.53426 7.85736 8.44792 7.62131 8.39612 7.47429C8.17332 6.84199 7.98457 6.31268 7.83107 6.00438Z" fill="#1090CB"/>
            </svg>
            8 727 310 02 58
          </div>
        </div>
        <a href="https://t.me/qabiletevent" class="modal-success-telegram">
          <svg width="20" height="19" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
            <path fill="#1090CB"
                d="m231.25586 31.73635a15.96358 15.96358 0 0 0 -16.29-2.76758l-184.55717 72.50488a15.99988 15.99988 0 0 0 2.7124 30.58106l46.87891 9.37598v58.55371a15.99415 15.99415 0 0 0 27.31348 11.31347l25.94336-25.94287 39.376 34.65088a15.86863 15.86863 0 0 0 10.51709 4.00293 16.15443 16.15443 0 0 0 4.96338-.78711 15.86491 15.86491 0 0 0 10.68457-11.65332l37.61374-164.13281a15.96073 15.96073 0 0 0 -5.15576-15.69922zm-145.10986 94.60644-49.88526-9.977 139.68164-54.87529zm9.854 73.63379v-47.40771l25.21973 22.1936zm87.20215 8.01758-82.39209-72.50586 118.645-85.687z"/>
          </svg>
          t.me/qabiletevent
        </a>
        <router-link to="/events" class="modal-success-btn">{{ $t('successfullyRegistered2') }}</router-link>
      </div>


    </el-dialog>

  </div>
</template>
<script>

export default {
  mounted() {
    const id = this.$route.params.id
    const type = this.$route.params.type

    if (type == 'seminar') {
      this.$http.get(window.API_ROOT + `/api/events/seminar/${id}`)
          .then(res => {

            this.seminarInfo = res.body

            this.seminarInfo.programs = this.seminarInfo.programs.map( item => {
              return {...item, moderator: this.seminarInfo.speakers.filter(speaker => speaker.id == item.seminar_id)[0]}
            })

            this.daysProgram = this.seminarInfo.programs.reduce((acc, program) => {
              let group = acc.find(item => item.day_ru === program.day_ru);
              if (group) {
                group.data.push(program);
              } else {
                acc.push({
                  day_ru: program.day_ru,
                  day_kz: program.day_kz,
                  day_en: program.day_en,
                  data: [program],
                });
              }
              return acc;
            }, []);

          })
    } else {
      this.$http.get(window.API_ROOT + `/api/events/training/${id}`)
          .then(res => {

            this.seminarInfo = res.body
            this.seminarInfo.forumPrograms = this.seminarInfo.forumPrograms.map( item => {
              return {...item, moderator: this.seminarInfo.speakers.filter(speaker => speaker.id == item.event_training_id)[0]}
            })

            this.daysProgram = this.seminarInfo.forumPrograms.reduce((acc, program) => {
              let group = acc.find(item => item.day_ru === program.day_ru);
              if (group) {
                group.data.push(program);
              } else {
                acc.push({
                  day_ru: program.day_ru,
                  day_kz: program.day_kz,
                  day_en: program.day_en,
                  data: [program],
                });
              }
              return acc;
            }, []);

          })
    }

    if (window.innerWidth < 1000) {
      // this.perPageSpeakers = 1
      this.perPageDays = 1
    }

  },
  methods: {
    getImagePath(path) {
      if (!path) return

      const formattedPath = encodeURI(path);

      if (formattedPath.startsWith('http://') || formattedPath.startsWith('https://')) {
        return formattedPath;
      } else {
        return this.backUrl + formattedPath;
      }
    },
    formatTime(time) {
      if (!time) return '';
      const [hours, minutes] = time.split(':');
      return `${hours}:${minutes}`;
    },
    formattedDate(date_from, date_to) {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const dateFrom = new Date(date_from).toLocaleDateString('ru-RU', options);
      const dateTo = new Date(date_to).toLocaleDateString('ru-RU', options);
      return `${dateFrom} - ${dateTo} гг`;
    },
    openModal() {
      this.modalSuccessfullyRegistered = true;
    }
  },
  filters: {

  },
  computed: {
    innerWidth() {
      return window.innerWidth > 1000
    },
    isBeforeForumDate() {
      if (this.seminarInfo.date_from) {
        const forumDate = new Date(this.seminarInfo.date_from);
        const currentDate = new Date();
        return currentDate < forumDate;
      } else {
        const forumDate = new Date(this.seminarInfo.start_date);
        const currentDate = new Date();
        return currentDate < forumDate;
      }
    },
  },
  data() {
    return {
      modalSuccessfullyRegisteredWidth: "410px",
      modalSuccessfullyRegistered: false,
      backUrl: window.API_ROOT + '/storage/',
      modalSignupForum: false,
      seminarInfo: {},
      perPageDays: 2.5,
      daysProgram: [],
    }
  }
}
</script>
<style>

.one-forum-program-days {
  margin-bottom: 30px;
}

.one-seminar-title-block {
  padding-top: 75px;
  height: 600px;
  border-bottom-left-radius: 20%;
  border-bottom-right-radius: 20%;
  position: relative;
  background: linear-gradient(90deg, #32436d 0%, #02c7fa 100%);
}

.one-forum-title {
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  color: #FFF;
}

.one-forum-subtitle {
  font-size: 30px;
  font-weight: 800;
  line-height: 45px;
  color: #FFF;
  display: flex;
  gap: 25px;
  align-items: center;
}

.one-forum-details-block {
  display: flex;
  justify-content: space-between;
}

.one-forum-details-left {
  width: 49%;
}

.one-forum-details-right {
  width: 49%;
  display: flex;
  flex-direction: column;
}

.one-forum-results {
  box-shadow: 0px 2.74px 105.6px 0px #0000000A;
  padding: 40px 0 40px 40px;
  position: relative;
}

.one-forum-results::before {
  position: absolute;
  content: "";
  width: 7px;
  height: 138px;
  background-color: #0CACDE;
  top: 50px;
  left: 40px;
}

.one-forum-results::after {
  position: absolute;
  content: "";
  width: 100vw;
  height: 100%;
  background-color: #FFF;
  top: 0px;
  left: 538px;
}

.one-seminar-section-title {
  font-size: 36px;
  font-weight: 600;
  line-height: 61.2px;
  color: #32436D;
  margin-bottom: 25px;
}

.one-forum-section-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 30.6px;
  color: #545454;
  margin-bottom: 25px;
}

.one-forum-details-block .line-top {
  margin-top: 40px;
  margin-bottom: 25px;
}

.one-forum-results-title {
  padding-left: 32px;
  font-size: 36px;
  font-weight: 600;
  line-height: 61.2px;
  color: #32436D;
}

.one-forum-results-text {
  padding-left: 32px;
  font-size: 18px;
  font-weight: 400;
  line-height: 30.6px;
  color: #545454;
  margin-bottom: 70px;
}

.one-forum-results-details {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #2E0D64;

  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.one-forum-speakers-block .line-top {
  margin-bottom: 21px;
}

.one-forum-speakers-slider {

}

.one-forum-slide-details {
  display: flex;
  gap: 25px;
  align-items: center;
}

.one-forum-slide-details img {
  border-radius: 50%;
}

.one-forum-slide-name {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #32436D;
}

.one-forum-slide-job {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #9497A1;
}

.one-forum-slide-text {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.015em;
  color: #9497A1;
}

.one-forum-slide {
  padding: 35px;
  box-shadow: 0px 4px 12px 0px rgba(12, 68, 204, 0.1);
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.one-forum-slide-block {
  padding: 4px;
  padding-right: 25px;
}

.one-forum-speakers-slider .VueCarousel-navigation-prev {
  top: -50px;
  left: 64% !important;
  background: #F0F9FF;
  width: 110px;
  height: 60px;
  color: #32436D !important;
  border-radius: 20px;
}

.one-forum-speakers-slider .VueCarousel-navigation-next {
  top: -50px;
  left: 59% !important;
  background: #F0F9FF;
  width: 110px;
  height: 60px;
  color: #32436D !important;
  border-radius: 20px;
}

.one-seminar-program-block {
  margin-bottom: 100px;
}

.one-seminar-program-block .line-top {
  margin-top: 30px;
  margin-bottom: 25px;
}

.one-seminar-program-block .one-seminar-section-title {
  margin-bottom: 25px;
}

.one-forum-program-link {
  border: 1.33px dashed #1090CB;

  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  color: #1090CB;
  padding: 16px 51px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  gap: 15px;
  width: fit-content;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 100px;
  transition: transform 0.5s ease;
}

.one-forum-program-link:hover {
  text-decoration: none;
  color: #1090CB;
  transform: scale(1.05);
}

.one-forum-program-day {
  max-width: 700px;
  padding: 33px 15px;
  box-shadow: 0px 2.74px 105.6px 0px #0000000A;

}

.day-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  color: #1090CB;
  margin-bottom: 40px;
}

.day-section-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: #545454;
  margin-bottom: 25px;
}

.accent-color {
  color: #1090CB;
}

.one-seminar-program-days {
  position: relative;
  height: 600px;
  margin: 50px 0;
}

.one-forum-program-day {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #FFF;
}

.one-forum-program-day:hover {
  z-index: 10;
}

.day-1 {
  z-index: 3;
  transform: translate(0px, 0px);
}

.day-2 {
  z-index: 2;
  transform: translate(200px, 0px);
}

.day-3 {
  z-index: 1;
  transform: translate(400px, 0px);
}

.one-seminar-program-link {
  background-color: #FA8443;
  padding: 12px 40px;
  border-radius: 14px;
  font-size: 16px;
  font-weight: 400;
  line-height: 32.69px;
  color: #FFF;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
  transition: transform 0.5s ease;
  margin: 0;
}

.one-seminar-program-link:hover {
  color: #FFF;
  text-decoration: none;
  cursor: pointer;
  transform: scale(1.05);
}

.one-forum-details-img {
  height: 583px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  background-repeat: no-repeat;
  background-size: 100% auto;
  margin-top: -330px;
  position: relative;
  z-index: 10;
}

.one-forum-tags {
  display: flex;
  gap: 4px;
}

.one-forum-tag {
  font-size: 20px;
  font-weight: 400;
  line-height: 23.44px;
  color: #FFF;
  background: rgba(255, 255, 255, 0.2);
  padding: 8px 10px;
  border-radius: 10px;
}

.one-forum-img-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 42.19px;
  letter-spacing: 0.5px;
  color: #FFF;
}

.one-forum-img-link {
  background-color: #1090CB;
  padding: 12px 90px;
  font-size: 16px;
  font-weight: 500;
  line-height: 32.69px;
  color: #FFF;
  border-radius: 14px;
  margin-top: 40px;
  display: inline-block;
}

.one-forum-img-link:hover {
  color: #FFF;
  text-decoration: none;
  cursor: pointer;
}

/*
.one-forum-program-days .VueCarousel-inner {
  width: 80%;
}
 */

.one-forum-speakers-slider .VueCarousel-navigation-prev {
  top: -50px;
  left: 88% !important;
  background: #F0F9FF;
  width: 110px;
  height: 60px;
  color: #32436D !important;
  border-radius: 20px;
  font-size: 0;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: center center;
  background-image: url("/images/arrow-desktop-left.svg");
}

.one-forum-speakers-slider .VueCarousel-navigation-next {
  top: -50px;
  left: 80% !important;
  background: #F0F9FF;
  width: 110px;
  height: 60px;
  font-size: 0;
  color: #32436D !important;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: center center;
  background-image: url("/images/arrow-desktop-right.svg");
}


@media screen and (max-width: 991px) {

  .one-forum-speakers-slider .VueCarousel-navigation-prev, .one-forum-speakers-block .one-training-speakers-slider .VueCarousel-navigation-prev {
    color: #32436D !important;
    font-size: 0;
    background: #C9DCEC !important;
    width: 48px;
    height: 40px;
    background-repeat: no-repeat !important;
    background-size: 24px 24px !important;
    background-position: center center !important;
    background-image: url("/images/arrow-mobile-left.svg") !important;
    position: absolute !important;
    left: 50px !important;
    top: -33px !important;
    cursor: pointer;
    border-radius: 14px;
  }

  .one-forum-speakers-slider .VueCarousel-navigation-next, .one-forum-speakers-block .one-training-speakers-slider .VueCarousel-navigation-next {
    color: #32436D !important;
    font-size: 0;
    background: #C9DCEC !important;
    width: 48px;
    height: 40px;
    background-repeat: no-repeat !important;
    background-size: 24px 24px !important;
    background-position: center center !important;
    background-image: url("/images/arrow-mobile-right.svg") !important;
    position: absolute !important;
    left: 20px !important;
    top: -33px !important;
    cursor: pointer;
    border-radius: 14px;
  }

}


</style>